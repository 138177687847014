<template>
  <footer class="landingFooter">
    <div class="container">
      <div class="row">
        <div class="col-md-3 mb-4 mb-md-0 d-flex flex-column align-items-center align-items-md-start">
          <img src="/nedcollege/NEDCOLLEGE_logo_light.svg" width="200" alt="ned_logo" class="ned_logo" />
          <div class="dateFooter d-none d-md-block">© 2023 NED College</div>
        </div>
        <div class="col-md-6 offset-md-3 mb-4 mb-md-0 text-center text-md-left">
          <div class="row">
            <div class="col-md-6 col-lg-6 mb-5 m-md-0">
              <div>
                <p class="text-green3"><b>Dublin Campus</b></p>
                <p>Dominick Street Lower</p>
                <p>Dublin 1 - D01 P9P4</p>
                <p>{{ $lang("footer.ireland", true) }}</p>
                <p>+353 1 878 3047</p>
                <p>dublin@ned.ie</p>
              </div>
            </div>
            <div class="col-md-6 col-lg-6 mb-5 m-md-0">
              <div>
                <p class="text-blue3"><b>Limerick Campus</b></p>
                <p>Chapel House</p>
                <p>Limerick - V94 YH6Y</p>
                <p>{{ $lang("footer.ireland", true) }}</p>
                <p>+353 (0) 61468571</p>
                <p>limerick@ned.ie</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 d-md-none text-center">
          <small class="dateFooter">© 2023 NED College</small>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
};
</script>
