<template>
  <div class="landing0823 thanks">
    <section>
      <img src="/landings/landing_0823/svgs/ned_logo.svg" class="logo" />
      <h1 v-html="$lang('title')" />

      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
    </section>

    <section>
      <div class="row justify-content-center text-center">
        <div class="col-9 col-md-5 d-flex flex-column align-items-center">
          <h2 class="text-blue2 mb-5" v-html="$lang('subtitle')" />

          <p class="mb-5" v-html="$lang('description')" />

          <h3>{{ $lang('socials_title') }}</h3>

          <div class="btn-group">
            <a href="https://www.instagram.com/nedcollege/" target="_blank" class="btn btn-green2 text-white btn-circle">
              <i class="fa-brands fa-instagram"></i>
            </a>
            <a href="https://www.facebook.com/NEDCollege/" target="_blank" class="btn btn-green2 text-white btn-circle">
              <i class="fa-brands fa-facebook-f"></i>
            </a>
            <a href="https://www.youtube.com/user/nedtcschool" target="_blank"
              class="btn btn-green2 text-white btn-circle">
              <i class="fa-brands fa-youtube"></i>
            </a>
            <a href="https://www.linkedin.com/company/nedcollege" target="_blank"
              class="btn btn-green2 text-white btn-circle">
              <i class="fa-brands fa-linkedin"></i>
            </a>
            <a href="https://www.tiktok.com/@nedcollege" target="_blank" class="btn btn-green2 text-white btn-circle">
              <i class="fa-brands fa-tiktok"></i>
            </a>
          </div>
        </div>
      </div>
    </section>

    <LandingFooter />
  </div>
</template>
  
<script>
import LandingFooter from "@/components/LandingFooter.vue";

export default {
  components: {
    LandingFooter
  }
}
</script>